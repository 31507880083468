/**
*  UI-REFACTORED
*    FROM --> ./legacy-web/src/main/js/js/aa/travelInformation/flightStatusTravelDates.js
*    TO   --> ./webapps/shared-web/src/main/assets/js/travel-information/flight-status-travel-dates.js
**/

AAcom.modules.travelDates = function(AAUI) {

	var defaultLocale = "en";

	AAUI.populateSpecificFlightSearchDateDropdown = function (target, includeYesterday, saveLocalTime) {
		var today = new Date();
        var daybeforeYesterday = new Date(); daybeforeYesterday.setDate(today.getDate()-2);
		var yesterday = new Date(); yesterday.setDate(today.getDate()-1);
		var tomorrow = new Date(); tomorrow.setDate(today.getDate()+1);
		var dayAfterTomorrow = new Date(); dayAfterTomorrow.setDate(today.getDate()+2);

		target.empty();

        addToDateDropdown(target, daybeforeYesterday, false);
        addToDateDropdown(target, yesterday, false);
		addToDateDropdown(target, today, true);
		addToDateDropdown(target, tomorrow, false);
		addToDateDropdown(target, dayAfterTomorrow, false);

		if (saveLocalTime !== undefined && saveLocalTime.length) {
			saveLocalTime.val(formatDate(today, defaultLocale));
		}
	};

	var dateFormat = {};
	dateFormat.i18n = {
		en: { monthNames: ['January','February','March','April','May','June', 'July','August','September','October','November','December'],
			  dayNames: ['Sunday','Monday','Tuesday','Wednesday','Thursday','Friday','Saturday'],
			  dateFormat: '{EEEE}, {MMMM} {d}'},
		pt: { monthNames: ['Janeiro','Fevereiro','Mar&ccedil;o','Abril','Maio','Junho','Julho','Agosto','Setembro','Outubro','Novembro','Dezembro'],
			  dayNames: ['Domingo','Segunda','Ter\u00E7a','Quarta','Quinta','Sexta','S\u00E1bado'],
			  dateFormat: '{EEEE}, {d} de {MMMM}'},
		es: { monthNames:["enero","febrero","marzo","abril","mayo","junio","julio","agosto","septiembre","octubre","noviembre","diciembre"],
			  dayNames: ["domingo","lunes","martes","miércoles","jueves","viernes","sábado"],
			  dateFormat: '{EEEE}, {MMMM} {d}'},
		ja: { monthNames:["1\u6708","2\u6708","3\u6708","4\u6708","5\u6708","6\u6708","7\u6708","8\u6708","9\u6708","10\u6708","11\u6708","12\u6708"],
	      	  dayNames:["\u65E5\u66DC\u65E5","\u6708\u66DC\u65E5","\u706B\u66DC\u65E5","\u6C34\u66DC\u65E5","\u6728\u66DC\u65E5","\u91D1\u66DC\u65E5","\u571F\u66DC\u65E5"],
	       	  dateFormat: '{EEEE}, {MMMM} {d}'},
	    fr: { monthNames: ['Janvier','Février','Mars','Avril','Mai','Juin', 'Juillet','Août','Septembre','Octobre','Novembre','Décembre'],
			  dayNames: ['Dimanche','Lundi','Mardi','Mercredi','Jeudi','Vendredi','Samedi'],
			  dateFormat: '{EEEE}, {MMMM} {d}'}
	};

	function addToDateDropdown (targetDropdown, date, isSelected) {
		var locale = getLocale();

		var formattedItem = formatDate(date, locale);
		var formattedValue = formatDate(date, defaultLocale);

		targetDropdown.append('<option value="'+formattedValue+'"'+(isSelected===true?'selected="selected"':'')+'>' + formattedItem + '</option');
	}

	function formatDate(date, locale) {
		var i18nDate = dateFormat.i18n[locale];

		if (i18nDate === undefined){
			return;
		}

		var format = i18nDate.dateFormat;
		var dayOfWeek = date.getDay();
		var dayOfMonth = date.getDate();
		var month = date.getMonth();

		var monthName = dateFormat.i18n[locale].monthNames[month];
		var dayName = dateFormat.i18n[locale].dayNames[dayOfWeek];

		return format.replace(/{d}/g, dayOfMonth).replace(/{EEEE}/g, dayName).replace(/{MMMM}/g, monthName);
	}

	function getLocale() {
		var locale = AAUI.getProperty('user.locale').substring(0,2);

		return dateFormat.i18n[locale] === undefined ? defaultLocale : locale;
	}
};

